module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-web-font-loader/gatsby-browser.js'),
      options: {"plugins":[],"custom":{"families":["HayFieldFreightProRegular, GillSansSemiBold, TTHovesRegular"],"urls":["/fonts/fonts.css"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-material-ui/gatsby-browser.js'),
      options: {"plugins":[],"stylesProvider":{"injectFirst":true}},
    },{
      plugin: require('../node_modules/gatsby-plugin-anchor-links/gatsby-browser.js'),
      options: {"plugins":[],"offset":-50},
    },{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"real-estate-capital-holdings","accessToken":"MC5ZTWR5ZnhJQUFDUUFlU1c5.G--_ve-_vQ3vv71gKB3vv71YM--_vSDvv71D77-9K--_ve-_vUAa77-977-9Ujjvv73vv71AbO-_vS7vv70","path":"/preview","previews":false,"pages":[{"type":"Standard_page","match":"/:uid","path":"/preview/standard-page-type-preview","component":"/opt/build/repo/src/templates/page.js"},{"type":"Contact_page","match":"/:uid","path":"/preview/contact-page-type-preview","component":"/opt/build/repo/src/templates/contact.js"},{"type":"Article","match":"news/:uid","path":"/preview/article-page-type-preview","component":"/opt/build/repo/src/templates/article.js"},{"type":"Basic_page","match":"/:uid","path":"/preview/basic-page-type-preview","component":"/opt/build/repo/src/templates/basic_page.js"}]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
