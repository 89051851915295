import { createMuiTheme, responsiveFontSizes } from '@material-ui/core/styles';

let theme = createMuiTheme({
  typography: {
    //fontFamily: 'HayFieldFreightProRegular, Arial, sans-serif',
    useNextVariants: true,
  },
  palette: {
    primary: {
      main: '#1F3242',
    },
    secondary: {
      main: '#FFF',
    },
    background: {
      default: '#FFF',
      primary: '#000',
      secondary: '#000',
      border: '#8ba4bb',
      disabled: '#1f324229',
      lgreen: '#577f76',
    },
    text: {
      primary: '#1F3242',
      secondary: '#22394c',
      light: '#c7d9e8',
      lgrey: '#d0cfd5',
      white: '#FFF',
      dblue: '#4f6e87',
      lblue: '#96adc4',
      label: '#2a4964',
      error: '#f44336',
      dgreen: '#344a51',
    },
  },
});

theme.overrides = {
  MuiContainer: {
    root: {
      [theme.breakpoints.up('md')]: {
        paddingLeft: '3vw',
        paddingRight: '3vw',
      },
    },
  },
  MuiCssBaseline: {
    '@global': {},
  },
  MuiTypography: {
    h1: {
      //fontFamily: 'GillSansSemiBold, Arial, sans-serif',
      fontSize: '3.3125rem',
      fontWeight: '600',
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: '4.25rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '4.25rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '4.25rem',
      },
    },
    h2: {
      //fontFamily: 'GillSansSemiBold, Arial, sans-serif',
      fontSize: '2.0625rem',
      fontWeight: '600',
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: '3.3125rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2.625rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.0625rem',
      },
    },
    h3: {
      //fontFamily: 'GillSansSemiBold, Arial, sans-serif',
      fontSize: '2.625rem',
      fontWeight: '600',
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.625rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '2.625rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '2.625rem',
      },
    },
    h4: {
      //fontFamily: 'GillSansSemiBold, Arial, sans-serif',
      fontSize: '1.625rem',
      fontWeight: '600',
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: '2.0625rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.625rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.625rem',
      },
    },
    h5: {
      //fontFamily: 'GillSansSemiBold, Arial, sans-serif',
      fontSize: '1.625rem',
      fontWeight: '400',
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.625rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.625rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.625rem',
      },
    },
    h6: {
      //fontFamily: 'GillSansSemiBold, Arial, sans-serif',
      fontSize: '1.25rem',
      fontWeight: '400',
      textTransform: 'uppercase',
      [theme.breakpoints.up('lg')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
      },
      [theme.breakpoints.up('sm')]: {
        fontSize: '1.25rem',
      },
    },
    body1: {
      fontFamily: 'HayFieldFreightProRegular, Arial, sans-serif',
      fontSize: '1rem', // 20px
      lineHeight: '2rem', // 40px
      [theme.breakpoints.up('md')]: {
        fontSize: '1.25rem',
        lineHeight: '2.5rem',
      },
    },
    body2: {
      fontSize: '1.25rem', // 20px
      lineHeight: '2.5rem', // 40px
      [theme.breakpoints.up('md')]: {
        fontSize: '1.625rem', // 26 px
      },
    },
    subtitle1: {
      //fontFamily: 'TTHovesRegular, Arial, sans-serif',
      fontSize: '1rem', // 16px
    },
    subtitle2: {
      //fontFamily: 'TTHovesRegular, Arial, sans-serif',
      fontSize: '0.875rem', // 14px
    },
    caption: {
      //fontFamily: 'TTHovesRegular, Arial, sans-serif',
      fontSize: '0.625rem', // 10px
    },
    button: {
      //fontFamily: 'TTHovesRegular, Arial, sans-serif',
      fontSize: '0.8125rem', // 13px,
      textTransform: 'none',
    },
  },
};

theme = responsiveFontSizes(theme);

export default theme;
