import React, { createContext, useReducer } from 'react';
import Reducer from './Reducer';
import PropTypes from 'prop-types';

const initialState = {
  menu: false,
  groupmenu: false,
  developmentsmenu: false,
  location: '',
  geometry: [],
  your_location: [],
  doLocationLookup: true,
  radius: 20,
  minprice: 0,
  bedrooms: 0,
  body_fixed: false,
  houseTypes: [],
  numOfBedsRange: [],
  priceRange: [],
};

const Store = ({ children }) => {
  const [state, dispatch] = useReducer(Reducer, initialState);
  return (
    <Context.Provider value={[state, dispatch]}>{children}</Context.Provider>
  );
};

Store.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Context = createContext(initialState);
export default Store;
