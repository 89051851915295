const Reducer = (state, action) => {
  switch (action.type) {
    case 'SET_MENU':
      return {
        ...state,
        menu: action.payload,
      };
    case 'SET_GROUP_MENU':
      return {
        ...state,
        groupmenu: action.payload,
      };
    case 'SET_DEVELOPMENTS_MENU':
      return {
        ...state,
        developmentsmenu: action.payload,
      };
    case 'SET_SEARCH':
      return {
        ...state,
        location: action.location,
        geometry: action.geometry,
        doLocationLookup: action.doLocationLookup,
        radius: action.radius,
        minprice: action.minprice,
        bedrooms: action.bedrooms,
        your_location: action.your_location,
        searchedLocation: action.searchedLocation,
        searchedRadius: action.searchedRadius,
        houseTypes: action.houseTypes,
        numOfBedsRange: action.numOfBedsRange,
        priceRange: action.priceRange,
      };
    case 'SET_BODY_FIXED':
      return {
        ...state,
        body_fixed: action.body_fixed,
      };
    default:
      return state;
  }
};

export default Reducer;
